<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div>
          <div class="columns">
            <div class="column">
              <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                <span class="has-text-weight-bold">Questionnaire History</span>
              </div>
              <div class="block" v-for="questionnaire in questionnaires" v-bind:key="questionnaire.id">
                {{ questionnaire.sectionIdentifierName }}<br />
                {{ questionnaire.userName }}<br />
                {{ questionnaire.createdAt }}<br />
                {{ questionnaire.oldResponseData }}<br />
                {{ questionnaire.newResponseData }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import Loading from './Loading'

let pageName = 'Questionnaire History'

export default {
  components: {
    Loading
  },
  data () {
    return {
      pageheading: pageName,
      isLoading: false,
      questionnaires: []
    }
  },
  computed: {
    ...mapState([activeSite]),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  async beforeRouteEnter (to, from, next) {
    let model = null
    next(async vm => {
      model = await vm.getQuestionnaireHistory(to.params.id)
      if (model) {
        vm.questionnaires = model
      }
    })
  },
  methods: {
    async getQuestionnaireHistory (id) {
      try {
        this.isLoading = true

        let route = `/questionnaires/${id}/history`
        let response = await this.axios.get(route)
        return response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
  #body-content-area {
    position: absolute;
    top: 35px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
  }

  #pagelayout {
    right: 100%;
    bottom: 0;
  }

  .subheading {
    font-size: 1em;
    padding: 30px 0 10px 0;
  }
</style>
